<template>
  <v-row no-gutters>
    <!-- <v-card class="pa-0" v-if="flat" flat>
      <v-card-text class="py-0">
        <v-row>
          <v-col>
            <vue-query-builder v-model="queryObject" ref="vqb" :rules="queryMap" :labels="labels" :styled="true">
              <template v-slot:default="slotProps">
                <v-query-builder-group v-bind="slotProps" :query.sync="queryObject" />
              </template>
            </vue-query-builder>
          </v-col>
        </v-row>
        <v-row class="px-2">
          <v-col cols="4">
            <v-btn class="mr-2" @click="dialogSavedFilters=true" outlined color="fontDraw" small>Filtros guardados</v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn class="mr-2" @click="dialogCreateFilter=true" :loading="$store.state.accounts.createLoader" color="primary" small>Guardar filtro</v-btn>
          </v-col>
          <v-col cols="4" class="text-right">
            <v-btn @click="buildQuery" color="primary" small>{{filterText}}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card> -->
    <template>
      <!-- new filters -->
      <v-dialog v-model="dialog" width="750" :scrollable="true" overlay-color="overlay">
        <template v-slot:activator="{on}">
          <v-btn v-on="on" outlined :disabled="disabled && !Object.keys($route.query).length"><v-icon left>mdi-filter-plus</v-icon>Filtros<template v-if="queryObject.children && queryObject.children.length"> | {{countFilter}}</template></v-btn>
        </template>
        <v-card class="pa-0" width="766">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 pt-1 subtitle-2 fontBody--text font-weight-medium"><v-icon color="fontBody" left size="20">mdi-filter</v-icon>Filtros</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3 pt-1">
                <v-btn @click="handlerClose" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
          <v-divider />
          <v-card-text class="pa-0 overflow-y-auto" style="max-height: 700px;" min-height="800" scrollable>
            <v-query-builder-group :rules.sync="queryMap" :queryObject.sync="queryObject" :isActiveFilter.sync="isActiveFilter" :cleanfilters="cleanfilters" :key="reset" />
          </v-card-text>
          <v-divider />
          <v-card-actions class="px-5 py-2 bgPanel" fixed>
            <v-btn class="ml-n0" @click.stop="removeQuery" text color="primary" :ripple="false">Limpiar</v-btn>
            <v-spacer />
            <v-btn class="d-none" @click="dialogSavedFilters=true" outlined color="fontDraw" small :ripple="false">Filtros guardados</v-btn>
            <!-- <v-btn class="d-none" @click="dialogCreateFilter=true" :loading="$store.state.accounts.createLoader" outlined small>Guardar filtro</v-btn> -->
            <v-btn @click="isActiveFilter=true;" color="primary" :ripple="false">{{filterText}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- end new filters -->
    </template>

    <!-- dialog saved filters -->
    <v-dialog v-model="dialogSavedFilters" width="750" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-toolbar class="transparent" flat height="55">
          <v-toolbar-title class="text--secondary subtitle-1 font-weight-medium">Seleccionar un filtro</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="pr-1">
              <v-btn icon retain-focus-on-click :ripple="false" small @click="dialogSavedFilters=false"><v-icon small>mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="pa-0">
          <v-skeleton-loader v-if="$store.state.accounts.retrieveLoader" transition="fade-transition" type="list-item" />
          <v-simple-table v-else-if="filtersList.length">
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, n) in filtersList" :key="n" @click="setFilter(item)" style="cursor: pointer">
                  <td>
                    <div class="d-flex">
                      <span class="body-1 text--secondary font-weight-medium">{{item.name}}</span>
                      <v-spacer />
                      <v-btn @click.stop="destroyFilter(n)" icon small color="red"><v-icon small>mdi-close</v-icon></v-btn>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-row class="mx-0" v-else>
            <v-col class="py-2 text-center">
              <v-icon color="fontDraw" size="70">mdi-filter-variant</v-icon>
              <span class="d-block subtitle-1 my-2">Aún no tienes filtros guardados</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end dialog saved filters -->

    <!-- dialog create filter -->
    <v-dialog v-model="dialogCreateFilter" width="400" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-toolbar class="transparent" flat height="55">
          <v-toolbar-title class="text--secondary subtitle-1 font-weight-medium">Guardar filtro</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="pr-1">
              <v-btn @click="dialogCreateFilter=false" icon small :ripple="false"><v-icon small>mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="pa-0">
          <v-list class="v-list-form py-6 transparent">
            <v-list-item>
              <v-list-item-title>Nombre</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model="$v.newFilter.name.$model" :error="$v.newFilter.name.$error" placeholder="Escribe un nombre..." maxlength="56" outlined required single-line dense hide-details />
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn @click="dialogCreateFilter=false" outlined color="fontDraw">Cancelar</v-btn>
          <v-btn @click="createFilter" :loading="$store.state.accounts.createLoader" color="primary">Guardar filtro</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog create filter -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import VQueryBuilderGroup from './VQueryBuilderGroup'
import ListViewMixin from '@/mixins/ListViewMixin'
export default {
  mixins: [
    ListViewMixin
  ],
  props: {
    queryMap: {
      type: Array,
      required: true
    },
    model: {
      type: String,
      required: true
    },
    flat: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    },
    filterText: {
      type: String,
      required: false,
      default () {
        return 'Filtrar'
      }
    },
    disabled: {
      type: Boolean,
      required: false
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  components: {
    // VueQueryBuilder,
    VQueryBuilderGroup
  },
  data () {
    return {
      reset: 0,
      cleanfilters: false,
      isActiveFilter: false,
      dialog: false,
      queryObject: {
        logicalOperator: 'all',
        children: []
      },
      labels: {
        matchType: 'Match Type',
        matchTypes: [
          {
            id: 'all',
            label: 'Todos'
          }
        ],
        addRule: 'Agregar filtro',
        removeRule: '&times;',
        addGroup: 'Crear grupo',
        removeGroup: '&times;',
        textInputPlaceholder: ''
      },
      operators: {
        contiene: '__icontains',
        'es menor a': '__lte',
        'es mayor a': '__gte',
        'es igual': ''
      },
      newFilter: {
        name: '',
        model: this.model
      },
      dialogSavedFilters: false,
      dialogCreateFilter: false,
      queryBackup: {}
    }
  },
  computed: {
    ...mapState({
      filtersCount: state => state.accounts.filtersCount,
      filtersList: state => state.accounts.filtersList
    }),
    countFilter () {
      return [...new Set(this.queryObject.children.map(item => item.query.rule))]?.length ?? 0
    }
  },
  watch: {
    isActiveFilter: {
      handler (val) {
        if (!val) this.buildQuery()
      },
      inmediate: true,
      deep: true
    },
    active: {
      handler (val) {
        this.dialog = val
      },
      inmediate: true,
      deep: true
    },
    dialog: {
      handler (val) {
       if (!val) this.$emit('update:active')
      },
      inmediate: true
    }
  },
  created () {
    // this.getFilters()
    if (Object.keys(this.$route.query).length) {
      this.backupQuery()
      this.setInitialQuery()
    } else this.removeQuery()
  },
  methods: {
    handlerClose () {
      this.dialog = false
      if (!Object.keys(this.$route.query).length) this.removeQuery()
    },
    getFilters () {
      const query = {
        model: this.model,
        limit: 0
      }
      this.$store.dispatch('accounts/LIST', {
        resource: 'filters',
        query: query
      })
    },
    buildQuery () {
      let queryAux = {}
      this.query = {}
      if (this.queryObject.children) {
        this.queryObject.children.forEach((d, i) => {
          if (d.query.operator) {
            const ruleID = `${d.query.rule}${this.operators[d.query.operator]}`
            queryAux[ruleID] = d.query.value
          } else {
            if (Array.isArray(d.query.value)) queryAux[`${d.query.rule}`] = `${d.query.value}`
            else queryAux[`${d.query.rule}`] = d.query.value
            // queryAux[`${d.query.rule}`] = d.query.value
          }
        })
        this.backupQuery()
        this.query = {
          ...(this.$route.name !== 'AccountMembers' && this.queryBackup),
          ...queryAux
        }
        this.setQuery()
        this.$emit('updateTab')
      }
      this.dialog = false
    },
    backupQuery () {
      if (Object.keys(this.$route.query).length && this.$route.name !== 'DownloadCenterList') {
        const queryStatusPendingsBackup = this.$route.query?.status__in ? JSON.parse(JSON.stringify(this.$route.query?.status__in)) : null
        const queryStatusDocsBackup = this.$route.query?.status__code__in ? JSON.parse(JSON.stringify(this.$route.query?.status__code__in)) : null
        const queryStatusPurchaseBackup = this.$route.query?.swap__status__in ? JSON.parse(JSON.stringify(this.$route.query?.swap__status__in)) : null
        const queryStatusBackup = this.$route.query?.status ? JSON.parse(JSON.stringify(this.$route.query?.status)) : null
        const queryTestBackup = this.$route.query?.test ? JSON.parse(JSON.stringify(this.$route.query?.test)) : null
        const queryPosIdBackup = this.$route.query?.posdocument__pos__id ? JSON.parse(JSON.stringify(this.$route.query?.posdocument__pos__id)) : null
        const queryBranchIdBackup = this.$route.query?.branch_id ? JSON.parse(JSON.stringify(this.$route.query?.branch_id)) : null
        const queryBatchIdBackup = this.$route.query?.batchdocument__batch ? JSON.parse(JSON.stringify(this.$route.query?.batchdocument__batch)) : null
        const queryCustomerBackup = this.$route.query?.customer ? JSON.parse(JSON.stringify(this.$route.query?.customer)) : null
        const querySupplierBackup = this.$route.query?.supplier ? JSON.parse(JSON.stringify(this.$route.query?.supplier)) : null
        const queryCustomerResponseBackup = this.$route.query?.customer_response__code__in ? JSON.parse(JSON.stringify(this.$route.query?.customer_response__code__in)) : null
        this.queryBackup = {
          ...(queryStatusPendingsBackup && { status__in: queryStatusPendingsBackup}),
          ...(queryStatusDocsBackup && { status__code__in: queryStatusDocsBackup}),
          ...(queryStatusPurchaseBackup && { swap__status__in: queryStatusPurchaseBackup}),
          ...(queryStatusBackup && { status: queryStatusBackup}),
          ...(queryTestBackup && { test: queryTestBackup}),
          ...(queryBranchIdBackup && { branch_id: queryBranchIdBackup}),
          ...(queryPosIdBackup && { posdocument__pos__id: queryPosIdBackup}),
          ...(queryBatchIdBackup && { batchdocument__batch: queryBatchIdBackup}),
          ...(queryCustomerBackup && { customer: queryCustomerBackup}),
          ...(querySupplierBackup && { supplier: querySupplierBackup }),
          ...(queryCustomerResponseBackup && { customer_response__code__in: queryCustomerResponseBackup})
        }
      }
    },
    setInitialQuery () {
      const initialQuery = {
        logicalOperator: 'All',
        children: []
      }
      Object.keys(this.$route.query).forEach((key) => {
        const operator = Object.values(this.operators).find(value => key.includes(value))
        const selectedOperator = Object.keys(this.operators).find(k => this.operators[k] === operator)
        const rule = key.replace(operator, '')
        const queryObject = this.queryMap.find(q => q.id === rule)
        if (queryObject) {
          initialQuery.children.push({
            type: 'query-builder-rule',
            query: {
              operand: queryObject.label,
              operator: selectedOperator,
              rule: rule,
              value: this.$route.query[key]
            }
          })
        }
        this.queryObject = initialQuery
        this.queryMap.forEach(children => {
          const rangeList = this.queryObject?.children?.filter((c) => c.query.rule === children.id)
          if (rangeList[1]?.query?.operator === 'es menor a' || rangeList[2]?.query?.operator === 'es mayor a') {
            children.operator = 'rango'
            if (rangeList[1]?.query?.operator === 'es menor a') {
              children.value2 = rangeList[1]?.query?.value ?? null
            } else if (rangeList[2]?.query?.operator === 'es mayor a') {
              children.value = rangeList[2]?.query?.value ?? null
            }
          } else {
            const value = this.queryObject?.children?.find((c) => c.query.rule === children.id)?.query?.value ?? null
            children.value = value
            const foundIndex = rangeList.findIndex(item => item.query.rule === children.id)
            if (foundIndex !== -1) {
              children.operator = rangeList[foundIndex]?.query?.operator ?? null
            }
          }
        })
      })
    },
    removeQuery () {
      this.reset += 1
      this.queryObject.children = []
      this.queryMap.forEach(children => {
        children.value = null
        children.value2 = null
      })
      this.$router.replace({
        query: {
          ...(this.$route.query.status__in && { status__in: this.$route.query.status__in }),
          ...(this.$route.query.status__code__in && { status__code__in: this.$route.query.status__code__in }),
          ...(this.$route.query.swap__status__in && { swap__status__in: this.$route.query.swap__status__in }),
          ...(this.$route.query.status && this.$route.name !== 'AccountMembers' && { status: this.$route.query.status }),
          ...(this.$route.query.test && { test: this.$route.query.test }),
          ...(this.$route.query.branch_id && { branch_id: this.$route.query.branch_id }),
          ...(this.$route.query.posdocument__pos__id && { posdocument__pos__id: this.$route.query.branch_id }),
          ...(this.$route.query.batchdocument__batch && { batchdocument__batch: this.$route.query.batchdocument__batch }),
          ...(this.$route.query.customer && { customer: this.$route.query.customer }),
          ...(this.$route.query.supplier && { supplier: this.$route.query.supplier }),
          ...(this.$route.query.customer_response__code__in && { customer_response__code__in: this.$route.query.customer_response__code__in })
        }
      }).catch(() => {})
      this.cleanfilters = true
    },
    setFilter (d) {
      this.queryObject = d.query_object
      this.dialogSavedFilters = false
    },
    createFilter () {
      this.$v.newFilter.$touch()
      if (this.$v.newFilter.$invalid) {
        return false
      }
      this.newFilter.query_object = this.queryObject
      this.$store.dispatch('accounts/CREATE', {
        resource: 'filters',
        payload: this.newFilter
      })
      .then((response) => {
        this.dialogCreateFilter = false
      })
      .catch(() => {
        this.$dialog.message.error('Error al crear el filtro.')
      })
    },
    destroyFilter (i) {
      this.$store.dispatch('accounts/DESTROY', {
        resource: 'filters',
        id: this.filtersList[i].id
      })
      .catch(() => {
        this.$dialog.message.error('Error al eliminar el filtro.')
      })
    }
  },
  validations: {
    newFilter: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(56)
      }
    }
  }
}
</script>